import React, { useEffect, useState, useRef } from 'react';
import { Col, Modal, Input, Button, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { apiGetIpcListByTenantId, apiPostIpc } from '../../apis/iot-hub.js';

const defaultElectricMeterTypeIpcName = 'MeterPC';

function InputInfo(props) {
  const firstRender = useRef(false);
  const [ loadingProductionIpcList, setLoadingProductionIpcList ] = useState(props.installationType === 'production' ? false : true);
  const [ errorGetProductionIpcList, setErrorGetProductionIpcList ] = useState(false);
  const [ productionIpcList, setProductionIpcList ] = useState([]);
  const [ submitLoading, setSubmitLoading ] = useState(false);
  const [ ipcName, setIpcName ] = useState('');
  const [ ipcNameInputStatus, setIpcNameInputStatus ] = useState(null);
  const [ productionIpcSelectStatus, setProductionIpcSelectStatus ] = useState(null);
  const [ backupOf, setBackupOf ] = useState(null);

  useEffect(() => {
    if( props.ipcMachineType === 'nc' ) {
      if( props.installationType === 'backup' && loadingProductionIpcList === true ) {
        apiGetIpcListByTenantId(props.tenantId).then((res) => {
          if( res.status === 200 ) {
            setProductionIpcList(res.data.filter((item) => item["backup_of"] === null && item["registered"] === true && item["synced"] === true ));
            setErrorGetProductionIpcList(false);
          } else {
            setErrorGetProductionIpcList(true);
          }
        }).catch((err) => {
          setErrorGetProductionIpcList(true);
        }).then(() => {
          setLoadingProductionIpcList(false);
        });
      }
    }
  }, [ props.tenantId, props.installationType, props.ipcMachineType, loadingProductionIpcList ]);

  const onSubmit = () => {
    if( submitLoading === true ) {
      return;
    }

    let hasError = false;

    let tmpIpcName = ipcName;
    if( ipcName === '' ) {
      if( props.ipcMachineType === '' || props.ipcMachineType === 'nc' ) {
        hasError = true;
        setIpcNameInputStatus('error');
      }

      if( props.ipcMachineType === 'meter' ) {
        tmpIpcName = defaultElectricMeterTypeIpcName;
      }
    }

    if( props.installationType === 'backup' && backupOf === null ) {
      hasError = true;
      setProductionIpcSelectStatus('error');
    }

    if( hasError ) {
      return;
    }

    setSubmitLoading(true);

    const data = { name: tmpIpcName, sn: props.serialNumber, backup_of: backupOf };

    if( props.ipcMachineType !== '' ) {
      data["ipc_type"] = props.ipcMachineType;
    } else {
      data["packages"] = props.packages;
    }

    if( props.modules !== null ) {
      data["modules"] = props.modules;
    }

    apiPostIpc(data).then((res) => {
      if( res.status === 200 ) {
        props.setCurrentIpc(res.data);
        props.goNextStep();
      } else {
        showErrorModal();
        setSubmitLoading(false);
      }
    }).catch((err) => {
      showErrorModal();
      setSubmitLoading(false);
    });
  };

  const showErrorModal = () => {
    Modal.error({
      title: "新增 IPC 失敗。",
      content: "請稍後重試，或聯絡管理員排除錯誤。",
      centered: true,
      maskClosable: false,
      okText: "確定",
      width: '70vh'
    });
  };

  if( firstRender.current === false ) {
    firstRender.current = true;

    if( props.ipcMachineType === 'meter' ) {
      onSubmit();
    }
  }

  return (
      submitLoading === false
      ?
        loadingProductionIpcList === false
          ?
            errorGetProductionIpcList === false
            ?
              <Col span={ 24 } className="center flex-column">
                { props.installationType === 'backup'
                  ?
                    <Select
                      size="large"
                      className="production-ipc-select"
                      placeholder="請選擇要備份的正式機"
                      allowClear={ true }
                      options={ productionIpcList.map((item) => {
                        return {
                            value: item.uuid,
                            label: item.name
                          };
                        })
                      }
                      onChange={(value) => {
                        if( value === undefined ) {
                          value = null;
                        } else {
                          setProductionIpcSelectStatus(null);
                        }
                        setBackupOf(value);
                      }}
                      dropdownStyle={{ textAlign: 'center' }}
                      status={ productionIpcSelectStatus }
                    />
                  :
                    null
                }
                { props.ipcMachineType === '' || props.ipcMachineType === 'nc'
                  ?
                    <Input
                      size="large"
                      className="ipc-input"
                      placeholder="請輸入 IPC 名稱"
                      value={ ipcName }
                      status={ ipcNameInputStatus }
                      onChange={(e) => {
                        setIpcNameInputStatus(null);
                        setIpcName(e.target.value.trim());
                      }}
                    />
                  :
                    null
                }
                <Button className="submit-btn" type="primary" size="large" onClick={ onSubmit }>
                  送出
                </Button>
              </Col>
            :
              <div className="get-production-ipc-error-text">
                獲取正式機列表出現錯誤，請稍後再試。
              </div>
          :
            props.installationType === 'backup'
            ?
              <>
                <Col span={ 24 } className="wait-loading-text center">
                  <div> 請等候載入正式機列表 </div>
                 </Col>
                <Col span={ 24 } className="wait-loading-icon center">
                  <LoadingOutlined />
                </Col>
              </>
            :
              null
      :
        <Col span={ 24 } className="center flex-column">
          <div className="start-process-text"> 裝機流程已啟動 </div>
          <div className="start-process-text"> 請耐心等候 </div>
          <LoadingOutlined className="start-process-icon" />
        </Col>
  );
}

export default InputInfo;
